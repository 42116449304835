@import './variables.scss';
@import './mixins.scss';

// KPKP UNCOMMENT AS NEEDED
html {font-size:17px;color:$navy;font-family:$body; background-color:$navy;}
body{margin:0;}

h1, h2, h3, h4, h5, p{margin-top:0;color:$navy;font-family:inherit;
  // span{font-size:inherit;color:inherit;font-weight:inherit;font-family:inherit;}
  &.up, span.up{text-transform:uppercase;}
  &.cap, span.cap{text-transform:capitalize;}
  &.em, span.em{font-style:italic;}
  &.bold, span.bold{font-weight:bold;}
}
h1, h2{line-height:1.2em;}
p, h4, h5{margin-bottom:20px;}
p{
  font-size:19px;
  line-height:1.6em;
}

a{text-decoration:none; color:inherit;
  &:hover{opacity:.8;text-decoration:none;@include ease(.3s);}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;
  font-size:inherit;letter-spacing:inherit;
  &:hover{cursor:pointer;color:inherit !important;}
}
img{@include center-block();max-width:100%;}
ul{list-style-type:none;margin:0;padding-left:0;}
ul.disc li{list-style-type:disc;}
// .visuallyHidden {@include visuallyHidden();}


.inside, .insideXl, .insideAlt, .insideTw, .medDef, .med2Def, .med3Def, .med4Def, .insideNrw{@include center-block();width:90%;max-width:1800px;}
.insideXl{width:95%;}
.insideAlt{width:85%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}

.pageStyles, .fullRow, .fullSec{@include sec-default();}
.centerBlock{@include center-block();}

.flexRow{display:flex;}
.flexRow.jc_between{justify-content:space-between;}
.flexRow.jc_evenly{justify-content:space-evenly;}
.flexRow.jc_around{justify-content:space-around;}

@include bp(lg){
  .flexRow.flex2 .flexItem{width:48%;}
}
@include bp(og){
  .flexRow{flex-direction:column;grid-row-gap:20px;}
}
// .bmargin0{margin-bottom:0;}
// .bmargin05{margin-bottom:5px;}
// .bmargin1{margin-bottom:10px;}
// .tmargin0{margin-top:0;}
// .tmargin2{margin-top:20px;}
// .textCenter{text-align:center;}
// .textLeft{text-align:left;}
// .textRight{text-align:right;}
// .tpadding4{padding-top:40px;}
// .tpadding6{padding-top:60px;}

// .spacer1,.spacer2,.spacer25,.spacer3,.spacer4{@include sec-default();}
// .spacer1{height:10px;}
// .spacer2{height:20px;}
// .spacer25{height:25px;}
// .spacer3{height:30px;}
// .spacer4{height:40px;}

// hr{border-color:rgba($black,.1);
// 	&.hlf,&.hlfC{width:50%;}
//   &.hlfC, &.center{@include center-block();}
// 	&.less{width:25%;}
// 	&.l120{width:120px;}
// }
.clearfix::after, .clearfixBtn::after,.clearfixBtn2::after {content:""; clear:both; display:table;}
.clearfix.btn, .clearfixBtn{@include sec-default(); margin-top:20px;}

.bumzLink{text-align:center; 
  font-size:16px; line-height:1em; font-weight:600; letter-spacing:.6px; @include tshadowl(rgba($black,.2));
  color:$white; background-color:$navy; border:2px solid $navy;
  padding:8px 23px; box-shadow:0 10px 25px rgba($black,.15);

  &:hover{text-decoration:none; @include ease(.3s); opacity:.85; color:$white;}
  &.full{width:100%; @include sec-default();}
  &.up{text-transform:uppercase;}
  &+.bumzLink{margin-left:15px;}
  
  @include bp(tb) {font-size:15px;}
}
.bumzLinkSm{font-size:14px; padding:5px 18px;}
.bumzLinkRev{color:$navy; background-color:$white; border-color:$white;
  &:hover{color:$navy;}
}

.textLink{font-weight:600;
  &:hover{color:darken($navy, 15%); opacity:1;}
  &.und{text-decoration:underline;}
}
