@import '../../assets/global.scss';

.ComingSoon{background:$navy;
  .treatSliderStackFont__000, .treatSliderBtmText__000{padding:75px 0 110px;position:relative;z-index:0;
    @include sec-bgimage('./images/beans4.jpg');background-position:top right;
    &::after{@include before();background-color:rgba($black, .07);}

    .bgWrap{padding:20px 0;position:relative;background-color:rgba($off,.9);}
    .bigHead, .mobBigHead{text-transform:uppercase;color:$purple;text-shadow:0px 1px 2px rgba($black, .5);margin-bottom:0;
      font-size:60px;font-weight:700;font-family:$nexa;letter-spacing:4px;
    }
    .mobBigHead, .mobImgRow{display:none;}
    .hd1{float:left;margin-left:30px;
      img{width:100%;max-width:300px;}
    }
    .hd2{position:absolute;bottom:0;left:0;width:100%;text-align:center;}
    
    .content{@include flex-default();align-items:center;justify-content:space-around; margin-top:-30px;}
    .text, .ukSlideshow{position:relative;z-index:2;}
    .text{width:50%;max-width:650px;}
    .mainHead{font-size:75px;font-weight:500;color:$navy;margin-bottom:0;}

    .ukSlideshow{width:100%;max-width:500px;
      &::before{@include before();height:500px;
        background-color:$pink;border-radius:50%;box-shadow: 0px 10px 30px rgba($black,.6); border-right:25px outset $pink;
      }
      ul{position:relative;z-index:2; margin-top:80px;}
    }

    .btmTextWrap{margin-top:60px;text-align:center;}
    .ukTextSlide{display:inline-block;
      ul{min-height:100px;width:unset;
        min-width:70% !important; max-width:100% !important;display:inline-block;vertical-align:top;
      }
      li{font-weight:700;}
    }

    @media (max-width:1500px){background-size:cover;}
    @include bp(tx){min-height:100vh;//82
      .ukSlideshow{max-width:430px;
        &::before{height:430px;}
      }
      .text{width:57%;max-width:550px;}
      .mainHead{font-size:67px;}
      .bigHead{font-size:50px;}
    }
    @include bp(tw){
      .hd1 img{max-width:270px;}
      .ukSlideshow{max-width:400px;
        &::before{height:400px;}
      }
      .text{max-width:520px;}
      .mainHead{font-size:63px;}
      .bigHead{font-size:47px;}
    }
    @media (max-width:1050px){
      .text{max-width:535px;margin-left:-1%;}
      .mainHead{font-size:56px;}
      .bigHead{font-size:42px;}
    }
    @include bp(br){
      .ukSlideshow{max-width:335px;margin-right:1%;
        &::before{height:335px;}
      }
      .mainHead{font-size:50px;}
      .bigHead{font-size:38px;}
    }
    @media (max-width:885px){background-image:url('../../assets/images/beans4mob.jpg');
      .hd1 img{max-width:250px;}
      .ukSlideshow{max-width:300px;
        &::before{height:300px;}
      }
      .mainHead{font-size:40px;}
      .btmTextWrap{margin-top:35px;}
      .bigHead{font-size:30px;}
    }
    @include bp(og){padding:0;text-align:center;min-height:unset; 
      .inside{width:100%;}
      .bgWrap{padding:10px 0 30px;min-height:100vh;}
      .hd1{@include sec-default();margin-left:0;
        img{max-width:180px;@include center-block();}
      }
      .content{flex-direction:column;margin-top:0;}

      .mobBigHead, .mobImgRow{@include sec-default();}
      .mobImgRow{
        img{display:inline-block;width:33%;max-width:200px;}
      }
      .ukSlideshow{display:none;}
      .text{@include sec-default();max-width:100%;margin:30px 0 15px;}
      .ukTextSlide ul{min-height:50px;}
      .mainHead{font-size:30px;}
      .bigHead{font-size:22px;letter-spacing:0px;}
      .btmTextWrap{display:none;}
    }
    @media (max-width:385px){
      .ukTextSlide ul{min-height:40px;}
      .mainHead{font-size:25px;}
      .bigHead{font-size:19px;}
    }
    @include bp(xx){
      .hd1 img{max-width:160px;}
      .mainHead{font-size:22px;}
      .bigHead{font-size:17px;}
    }
  }

  .treatSliderStackFont, .treatSliderBtmText{position:relative;z-index:0;
    @include sec-bgimage('./images/beans4.jpg');background-position:top right;
    &::after{@include before();background-color:rgba($black, .07);}

    .bgWrap{padding:20px 0 60px;position:relative;background-color:rgba($off,.9);}
    .newBigHead{font-size:45px;font-weight:700;font-family:$nexa;letter-spacing:2px;color:$purple;text-shadow:0px 1px 2px rgba($black, .5);margin-bottom:0;}
    .mobImgRow{display:none;}
    .hd1{float:left;margin-left:30px;
      img{width:100%;max-width:300px;}
    }
    .hd2{position:absolute;bottom:0;left:0;width:100%;text-align:center;}
    
    .content{@include flex-default();align-items:center;justify-content:space-around; margin-top:-30px;}
    .text, .ukSlideshow{position:relative;z-index:2;}
    .text{width:50%;max-width:650px;}
    .mainHead{font-size:75px;font-weight:500;color:$navy;margin-bottom:0;}

    .ukSlideshow{width:100%;max-width:500px;margin-top:-50px;
      &::before{@include before();height:500px;
        background-color:$pink;border-radius:50%;box-shadow: 0px 10px 30px rgba($black,.6); border-right:25px outset $pink;
      }
      ul{position:relative;z-index:2; margin-top:80px;}
    }

    .btmTextWrap{margin-top:60px;text-align:center;}
    .ukTextSlide{display:inline-block;
      ul{min-height:100px;width:unset;
        min-width:70% !important; max-width:100% !important;display:inline-block;vertical-align:top;
      }
      li{font-weight:700;}
    }

    h1, .socBox{display:inline-block;vertical-align:top;}
    .socBox{margin-left:30px;
      svg{width:40px;height:40px;color:$purple;}
    }

    @media (min-width:1860px){
      .content{margin-top:150px;}
    }
    @media (max-width:1500px){background-size:cover;}
    @include bp(tx){
      .ukSlideshow{max-width:430px;
        &::before{height:430px;}
      }
      .text{width:57%;max-width:550px;}
      .mainHead{font-size:67px;}
    }
    @include bp(tw){
      .bgWrap{padding-bottom:40px;}
      .hd1 img{max-width:270px;}
      .ukSlideshow{max-width:400px;
        &::before{height:400px;}
      }
      .text{max-width:520px;margin-top:20px;}
      .mainHead{font-size:63px;}
      .newBigHead{font-size:40px;}

      .socBox svg{width:35px;height:35px;}
    }
    @media (max-width:1050px){
      .text{max-width:535px;margin-left:-1%;}
      .mainHead{font-size:56px;}
    }
    @include bp(br){
      .ukSlideshow{max-width:335px;margin-right:1%;
        &::before{height:335px;}
      }
      .mainHead{font-size:50px;}
      .newBigHead{font-size:30px;}
      .socBox{margin-left:20px;
        svg{width:30px;height:30px;}
      }
    }
    @media (max-width:885px){background-image:url('../../assets/images/beans4mob.jpg');
      .hd1 img{max-width:250px;}
      .ukSlideshow{max-width:300px;
        &::before{height:300px;}
      }
      .mainHead{font-size:40px;}
      .btmTextWrap{margin-top:35px;}
      .bigHead{font-size:30px;}
    }
    @include bp(og){text-align:center;
      .bgWrap{padding:10px 0 20px;}
      .hd1{@include sec-default();margin-left:0;
        img{max-width:180px;@include center-block();}
      }
      .content{flex-direction:column;margin-top:0;}

      .mobImgRow{@include sec-default();
        img{display:inline-block;width:33%;max-width:160px;}
      }
      .ukSlideshow{display:none;}
      .text{@include sec-default();max-width:100%;margin:40px 0 5px;}
      .ukTextSlide ul{min-height:50px;}
      .mainHead{font-size:30px;}
      .newBigHead{font-size:21px;letter-spacing:0;}
      .btmTextWrap{display:none;}
      h1, .socBox{@include sec-default();}
      .socBox{margin-left:0;
        svg{width:22px;height:22px;}
      }
    }
    @media (max-width:385px){
      .ukTextSlide ul{min-height:40px;}
      .mainHead{font-size:25px;}
      .newBigHead{font-size:19px;}
    }
    @include bp(xx){
      .hd1 img{max-width:160px;}
      .mainHead{font-size:22px;}
      .newBigHead{font-size:17px;}
    }
  }

  .intro{padding:80px 0 60px;background-color:$blue;text-align:center;
    h2, h3, p{color:$white;}
    h2, h3{line-height:1em;font-family:$nexa;font-weight:700;}
    h2{font-size:60px;margin-bottom:10px;}
    h3{font-size:40px;}
    p{font-size:17px;font-weight:300;}

    @include bp(nn){padding:50px 0 20px;
      h2{font-size:50px;}
      h3{font-size:30px;}
    }
    @include bp(og){
      h2{font-size:40px;}
      h3{font-size:21px;}
      p{font-size:16px;}
    }
    @include bp(fi){padding:30px 0 10px;
      h2{font-size:30px;}
      h3{font-size:17px;}
    }
  }
  .menus{padding:80px 0;text-align:center;
    h3, p{color:$white;}
    h3{font-size:60px;letter-spacing:3px;line-height:1em;font-family:$nexa;font-weight:700;margin-bottom:0;}
    p{font-size:17px;font-weight:300;margin-bottom:50px;}

    @include bp(nn){padding:50px 0;
      h3{font-size:40px}
      p{font-size:16px;margin-bottom:30px;}
    }
    @include bp(og){padding:30px 0;
      .sectionWrap{max-width:450px;}
      h3{font-size:30px;letter-spacing:0;}
      p{margin-bottom:20px;}
    }
  }
}