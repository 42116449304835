@import '../../assets/global';

// KPKP REMOVE AS NEEDED

.Header{@include sec-default(); text-align:center; padding:10px 0 15px;
  background-color:$white;

  .inside{max-width:1500px;}
  .hdCol{display:inline-block; vertical-align:top;}
  .logoCol, .navCol{float:left;}
  .navCol, .contactCol{margin-top:55px;}
  .logoCol{max-width:150px;}
  
  .contactCol{float:right; text-align:right;
    a+a{margin-left:30px;}
  }

  .navCol{margin-left:20px;
    ul{display:inline-block; vertical-align:top;}
    ul+ul{margin-left:5px;}
    svg{width:20px; height:20px;}
  }
  .navItem{font-size:18px; display:inline-block; margin:0 20px;}


  .mobNav{display:none; float:right; margin-top:30px;}
  .mobileNavBtn{@include sec-default(); width:35px; background-color:$dark; padding:5px 7px; box-shadow:0px 10px 30px rgba($black,.2);
    span{@include sec-default();height:2px;margin:2.5px 0;background-color:$white;}
    p,.drawerText{display:none;}
  }
}

.SideNavList{padding:40px 30px 0;width:300px;
  .SideNav{margin-top:10%;}
  .closeIcon {color:$white;position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:30px;height:30px;}
  }

  .navItemCat{@include sec-default();margin:10px 0 0;font-weight:bold;text-decoration:underline;font-size:16px;text-transform:uppercase;color:$white;}
  .navItem,.dropLi{@include sec-default();line-height:1em;margin:0 0 25px;
    &.mob{display:block;}
    &.fw{display:none;}

    &.withDrop{margin:10px 0 0;
      & > a{text-decoration:underline;font-weight:900;text-transform:uppercase;font-size:15px;letter-spacing:2px;}
    }

    a, button{font-weight:400;color:$white;text-transform:none;font-size:19px;line-height:1em;padding:0;
      &:hover{opacity:.7;color:$white;}
    }
    span.num, span.parentIcon{display:none;}
  }
  .custDrop{@include sec-default();margin-top:5px;background-color:transparent;padding:0;position:relative;box-shadow:none;}
}
