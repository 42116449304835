@import url("https://use.typekit.net/vxz8wsq.css");

$black:#000;
$white:#fff;
$red:#ed1e24;
$light:#ececec;
$f7:#f7f7f7;
$f5:#f5f5f5;
$fa: #fafafa;
$dark:#1a1a1a;
$gray:#9d9d9d;
$off:#f5f4f4;
$bg:#FAF7F3;
$bg2:#FBF9F6;

$navy:#1f2f4a;
$blue:#344b83;
$brown:#8f6947;

$pink:#B94895;
$purple:#4E3283;
$teal:#00B8B8;

$nexa: "nexa-rust-sans-black-2", sans-serif;
$avant: "itc-avant-garde-gothic-pro", sans-serif;


// $borderB:1px solid rgba($black,.1);
// $borderW:1px solid rgba($white,.1);
// $borderR:1px solid rgba($red,.1);

$lato:'Lato', sans-serif;

$body:$avant;
// $emph: $bounce;