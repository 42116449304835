@import '../../assets/global.scss';

.Social{display:inline-block;
  li{display:inline-block; margin-right:10px;
    &:last-child{margin-right:0;}
  }
  svg{width:18px; height:18px;}

  // &.nav{
  //   svg{width:20px; height:20px;}
  // }
}