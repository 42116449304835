@import '../../assets/global.scss';

.Home{
  .treatSlider{padding:200px 0 100px; background-color:$bg; position:relative;z-index:0;
    &::after{@include before();background-color:rgba($black, .07);}
      &::before{@include before();
      // @include sec-bgimage('./images/beans2.png'); //background-position: center bottom;
      // @include sec-bgimage('./images/beans1.png'); background-position: right center;
      // @include sec-bgimage('./images/beans3.png');  background-position: center top; height:90%; top:auto;bottom:0;
      // z-index:1;
      @include sec-bgimage('./images/beans4.jpg');
      // @include sec-bgimage('./images/wave3.png'); //background-position: center bottom;
    }

    .text, .ukSlideshow{display:inline-block; position:relative;}
    
    .text{width:50%;float:left; margin-top:30px; z-index:2;}
    h1{font-size:90px;font-weight:900;
      color:$teal;
      text-transform:uppercase;
      text-shadow:0px 1.5px darken($teal, 15%);
    }

    .ukSlideshow{width:100%;max-width:500px;float:right; //z-index:0;
      z-index: 1;
      &::before{@include before();height:500px;top:-80px; background-color:$pink;border-radius:50%;
        box-shadow: 0px 10px 30px rgba($black,.6);
        border-right:25px outset $pink;
      }
      ul{position:relative;z-index:2;}
    }
  }

  .menuSec{margin:400px 0; padding: 60px 0;}
  .contactSec{padding: 60px 0; background-color:$bg;}

  .ComingSoon{background:$navy;
    .treatSliderStackFont, .treatSliderBtmText{padding:75px 0 110px;position:relative;z-index:0;
      @include sec-bgimage('./images/beans4.jpg');background-position:top right;
      &::after{@include before();background-color:rgba($black, .07);}
  
      .bgWrap{padding:20px 0;position:relative;background-color:rgba($off,.9);}
      .bigHead, .mobBigHead{text-transform:uppercase;color:$purple;text-shadow:0px 1px 2px rgba($black, .5);margin-bottom:0;
        font-size:60px;font-weight:700;font-family:$nexa;letter-spacing:4px;
      }
      .mobBigHead, .mobImgRow{display:none;}
      .hd1{float:left;margin-left:30px;
        img{width:100%;max-width:300px;}
      }
      .hd2{position:absolute;bottom:0;left:0;width:100%;text-align:center;}
      
      .content{@include flex-default();align-items:center;justify-content:space-around; margin-top:-30px;}
      .text, .ukSlideshow{position:relative;z-index:2;}
      .text{width:50%;max-width:650px;}
      .mainHead{font-size:75px;font-weight:500;color:$navy;margin-bottom:0;}
  
      .ukSlideshow{width:100%;max-width:500px;
        &::before{@include before();height:500px;
          background-color:$pink;border-radius:50%;box-shadow: 0px 10px 30px rgba($black,.6); border-right:25px outset $pink;
        }
        ul{position:relative;z-index:2; margin-top:80px;}
      }
  
      .btmTextWrap{margin-top:60px;text-align:center;}
      .ukTextSlide{display:inline-block;
        ul{min-height:100px;width:unset;
          min-width:70% !important; max-width:100% !important;display:inline-block;vertical-align:top;
        }
        li{font-weight:700;}
      }
  
      @media (max-width:1500px){background-size:cover;}
      @include bp(tx){min-height:100vh;//82
        .ukSlideshow{max-width:430px;
          &::before{height:430px;}
        }
        .text{width:57%;max-width:550px;}
        .mainHead{font-size:67px;}
        .bigHead{font-size:50px;}
      }
      @include bp(tw){
        .hd1 img{max-width:270px;}
        .ukSlideshow{max-width:400px;
          &::before{height:400px;}
        }
        .text{max-width:520px;}
        .mainHead{font-size:63px;}
        .bigHead{font-size:47px;}
      }
      @media (max-width:1050px){
        .text{max-width:535px;margin-left:-1%;}
        .mainHead{font-size:56px;}
        .bigHead{font-size:42px;}
      }
      @include bp(br){
        .ukSlideshow{max-width:335px;margin-right:1%;
          &::before{height:335px;}
        }
        .mainHead{font-size:50px;}
        .bigHead{font-size:38px;}
      }
      @media (max-width:885px){background-image:url('../../assets/images/beans4mob.jpg');
        .hd1 img{max-width:250px;}
        .ukSlideshow{max-width:300px;
          &::before{height:300px;}
        }
        .mainHead{font-size:40px;}
        .btmTextWrap{margin-top:35px;}
        .bigHead{font-size:30px;}
      }
      @include bp(og){padding:0;text-align:center;min-height:unset; 
        .inside{width:100%;}
        .bgWrap{padding:10px 0 30px;min-height:100vh;}
        .hd1{@include sec-default();margin-left:0;
          img{max-width:180px;@include center-block();}
        }
        .content{flex-direction:column;margin-top:0;}
  
        .mobBigHead, .mobImgRow{@include sec-default();}
        .mobImgRow{
          img{display:inline-block;width:33%;max-width:200px;}
        }
        .ukSlideshow{display:none;}
        .text{@include sec-default();max-width:100%;margin:30px 0 15px;}
        .ukTextSlide ul{min-height:50px;}
        .mainHead{font-size:30px;}
        .bigHead{font-size:22px;letter-spacing:0px;}
        .btmTextWrap{display:none;}
      }
      @media (max-width:385px){
        .ukTextSlide ul{min-height:40px;}
        .mainHead{font-size:25px;}
        .bigHead{font-size:19px;}
      }
      @include bp(xx){
        .hd1 img{max-width:160px;}
        .mainHead{font-size:22px;}
        .bigHead{font-size:17px;}
      }
    }
  }
}
